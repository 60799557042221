import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth0 } from './react-auth0-spa';

const PrivateRoute = ({children, ...rest}: any) => {
    const {isAuthenticated} = useAuth0();
    return <Route
        {...rest}
        render={({ location }) =>
          isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      />
  }
  export default PrivateRoute;