import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    margin: 2rem;
    border: 0.2rem solid #282c34;
    padding: 0.3rem;
    border-radius: 0.5rem;
    box-shadow: #282c34 0.2em 0.2rem;
`;

const MediaController = (props: any) => {
    
    return <Container>
        <h3> Here be some media controllers</h3>
    </Container>
};

export default MediaController;