import React, { useEffect, useState } from "react";
import {useCookies} from 'react-cookie';


const Loading = () => {
    const [,, removeCookie] = useCookies(['auth0.is.authenticated']);
    const doLogout = () => {
        removeCookie('auth0.is.authenticated')
        window.location.reload();
    }
    const [slowLoading, setSlowLoading] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
          setSlowLoading(true);
        }, 5000);
        return () => clearTimeout(timer);
      }, []);
    
    return <div>Loading... <div hidden={!slowLoading}>Ser ut som ting ikke laster helt etter planen. Klikk her for å logge ut og prøve på nytt: <button  onClick={doLogout}>Logg ut</button></div></div>;
}

export default Loading;