import React from 'react';
import styled from 'styled-components';
import { Route, Switch } from "react-router-dom";
import {ResetStyles, BaseStyles} from '../../styles'
import { useAuth0 } from '../auth/react-auth0-spa';
import Login from '../auth/Login';
import Profile from '../auth/Profile';
import PrivateRoute from '../auth/PrivateRoute';
import Loading from '../Loading/Loading';
import MediaController from '../piui/MediaController';


const Container = styled.div`
  text-align: center;
`;

const AppHeader = styled.div`
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
`;

function App() {
  const { loading } = useAuth0();
  if (loading) {
      return <Loading />;
    }
  return (
    <>
    <ResetStyles />
    <BaseStyles />

    <Container>
      <AppHeader><h1>TabFam Media Kontrollpanel</h1></AppHeader>
      
        <Switch>
          <Route path="/login" component={Login}/>
          <PrivateRoute path="/profile">
            <Profile />
          </PrivateRoute>
          <Route path="/">
            <MediaController />
          </Route>
        </Switch>
    </Container>
    </>
  );
}

export default App;
